import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Home/Header';
import Footer from '../Footer';
import InterviewBanner from './InterviewBanner';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Interview() {
  const [videos, setVideos] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    axios.get('https://login.leprobat.com/api/videos/read')
      .then(response => {
        setVideos(response.data);
        console.log(response.data);
      })
      .catch(error => console.error('Error fetching videos:', error));
  }, []);

  const listStyle = {
    listStyle: 'none',
    paddingLeft: 0,
  };

  const listItemStyle = {
    lineHeight: '1.5em',
    paddingLeft: '1.5em',
    paddingTop: "2em",
    position: 'relative',
  };

  const checkMarkStyle = {
    content: '"✔"',
    color: '#FF5C00',
    position: 'absolute',
    left: 0,
  };

  return (
    <div>
      <Header />
      <InterviewBanner />
      <div className="auto-container mt-5">
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 content-side">
            <div className="service-details-content">
              <div className="content-one">
                <div className="text-box">
                  <h2>{t('maintenance_title')}</h2>
                  <p>{t('maintenance_description')}</p>
                </div>
                <div className="text-center">
                  <div className="cta-1-link-bt">
                    <NavLink to="/contact" className="btn-1">{t('Contact Us')}<span /></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="cta-1-section">
        <div className="cta-1-bg" data-parallax="{&quot;y&quot;: 30}" style={{ backgroundImage: 'url(assets/images/background/bg-1.jpg)' }} />
        <div className="auto-container">
          <div className="section_heading text-center mb_40">
            <h2 className="section_heading_title_big c_light fs_40 mb-4">{t('cta_title')}</h2>
            <p style={{ color: 'white' }}>{t('cta_description')}</p>
          </div>
          <div className="text-center">
            <div className="cta-1-link-bt">
              <NavLink to="/contact" className="btn-1">{t('Contact Us')}<span /></NavLink>
            </div>
          </div>
        </div>
      </section>
      <div className="auto-container mt-5">
        <div className="row">
          <div className="col-lg-6 ps-lg-5">
            <div className="section_heading mb_20">
              <h3 className="section_heading_title_big">{t('our_services')}</h3>
            </div>
            <ul style={listStyle}>
              <li style={listItemStyle}>
                <span style={checkMarkStyle}>✔</span>{t('service_vmc')}
              </li>
              <li style={listItemStyle}>
                <span style={checkMarkStyle}>✔</span>{t('service_electrical_panels')}
              </li>
              <li style={listItemStyle}>
                <span style={checkMarkStyle}>✔</span>{t('service_emergency_repairs')}
              </li>
              <li style={listItemStyle}>
                <span style={checkMarkStyle}>✔</span>{t('service_apartment_standards')}
              </li>
              <li style={listItemStyle}>
                <span style={checkMarkStyle}>✔</span>{t('service_electric_heaters')}
              </li>
              <li style={listItemStyle}>
                <span style={checkMarkStyle}>✔</span>{t('service_water_heater')}
              </li>
              <li style={listItemStyle}>
                <span style={checkMarkStyle}>✔</span>{t('service_leak_detection')}
              </li>
              <li style={listItemStyle}>
                <span style={checkMarkStyle}>✔</span>{t('service_sanitary_facilities')}
              </li>
            </ul>
            <div className="cta-1-link-bt mt-5">
              <NavLink to="/contact" className="btn-1">{t('Contact Us')}<span /></NavLink>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-1-image-wrap text-center mb_30 p_relative">
              <div className="d_iblock">
                <div className="about-1-image-1 hvr-img-zoom-1">
                  <img src="assets/images/resource/about14.jpg" style={{ width: 540 }} alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Array.isArray(videos) && videos.map(video => {
        const videoId = video.link.split('/').pop().split('?')[0]; // Extract the video ID from the link
        return (
          <section key={video.id} className="customer-review-video">
            <div className='container p-5'>
              <div className="video">
                <h3 className="section_heading_title_big mb-4">{video.title}</h3>
                {videoId && (
                  <iframe
                    src={`https://www.youtube.com/embed/${videoId}`}
                    width={600}
                    height={535}
                    frameBorder={0}
                    style={{ border: 0, width: '100%' }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                  />
                )}
                <p className='mt-3'>{video.description}</p>
              </div>
            </div>
          </section>
        );
      })}
      <Footer />
    </div>
  );
}
