import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { useTranslation } from 'react-i18next';


export default function HomeBanner() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { t } = useTranslation();


  return (
    <section className="banner-section banner-style-1">
      <Swiper
        className="banner-slider"
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          prevEl: '.banner-slider-button-prev',
          nextEl: '.banner-slider-button-next',
        }}
        loop={true}
      >
        {/* Slide Item */}
        <SwiperSlide
          style={{ backgroundImage: 'url(assets/images/main-slider/banner-1.jpg)' }}
        >
          <div className="content-outer">
            <div className="content-box">
              <div className="inner">
                <h4>LEPROBAT</h4>
                <h1>{t('RENOVATION COMPANY IN MARSEILLE')}</h1>
                <div className="text">
                  {t('We have been supporting you for over 20 years in your interior and exterior renovation projects, all trades.')}
                </div>
                <div className="link-box">
                  <NavLink to="/contact" className="btn-1">
                    {t('Contact Us')} <span />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* Slide Item */}
        <SwiperSlide
          style={{ backgroundImage: 'url(assets/images/main-slider/banner-2.jpg)' }}
        >
          <div className="content-outer">
            <div className="content-box">
              <div className="inner">
                <h4>{t('20 Years Of Experience')}</h4>
                <h1>{t('PROFESSIONAL RENOVATION SERVICES')}</h1>
                <div className="text">
                 {t(' With two decades of experience, we specialize in transforming residential and commercial spaces. From kitchens and bathrooms to complete home renovations, our skilled team ensures top-quality workmanship in every project.')}
                </div>
                <div className="link-box">
                <NavLink to="/contact" className="btn-1">
                {t('Contact Us')} <span />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* Slide Item */}
        <SwiperSlide
          style={{ backgroundImage: 'url(assets/images/main-slider/banner-3.jpg)' }}
        >
          <div className="content-outer">
            <div className="content-box">
              <div className="inner">
                <h4>{t('20 Years Of Experience')}</h4>
                <h1>{t('COMPREHENSIVE RENOVATION SOLUTIONS')}</h1>
                <div className="text">
                  {t("Our dedicated team of experts has been delivering exceptional renovation services in Marseille for over 20 years. Whether it's updating your living space or enhancing your commercial property, we handle all aspects of the renovation process with precision and care.")}
                </div>
                <div className="link-box">
                  <NavLink to="/contact" className="btn-1">
                  {t('Contact Us')}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      {/* <div className="banner-slider-nav">
        <div className="banner-slider-control banner-slider-button-prev">
          <span>
            <i className="icon-4" />
          </span>
        </div>
        <div className="banner-slider-control banner-slider-button-next">
          <span>
            <i className="icon-3" />
          </span>
        </div>
      </div> */}

      <div className="banner-shape">
        <img src="assets/images/shape/banner-shape.svg" alt="Banner Shape" />
      </div>
    </section>
  );
}
