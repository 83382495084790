import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function Header() {

    const options = [
        {
            value: "en",
            label: (
                <div>
                    <img src="assets/images/flag-usa.png" width="20" alt="English" /> English
                </div>
            )
        },
        {
            value: "fr",
            label: (
                <div>
                    <img src="assets/images/flag-usa.png" width="20" alt="Français" /> Français
                </div>
            )
        }
    ];

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();

    const { i18n } = useTranslation();

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
    };


    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    const toggleMenu = () => setIsOpen(!isOpen);

    // Scroll to top on location change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div>
            <div className="">
                <div className="preloader"><div className="preloader-close">Preloader Close</div></div>
                <div className="layer layer-one"><span className="overlay" /></div>
                <div className="layer layer-two"><span className="overlay" /></div>
                <div className="layer layer-three"><span className="overlay" /></div>
            </div>
            <header className="main-header header-style-one">
                <div className="header-upper">
                    <div className="auto-container">
                        <div className="inner-container d-flex align-items-center justify-content-between">
                            <div className="left-column d-flex align-items-center">
                                <div className="logo-box">
                                    <div className="logo">
                                        <NavLink to="/"><img src="assets/images/logo.png" alt="Logo" /></NavLink>
                                        {/* <NavLink to="/"><img src="assets/images/flag-usa.png" alt="Logo" /></NavLink> */}
                                        {/* <NavLink to="/"><img src="assets/images/flag-usa.png" alt="Logo" /></NavLink> */}
                                    </div>
                                </div>
                                <div className="ml_60 mr_60 d-none d-xl-block">
                                    <img src="assets/images/shape/shape-1.png" alt="Shape" />
                                </div>
                                <div className="nav-outer">
                                    <div className="language-selector-mob">
                                        <select onChange={handleLanguageChange} className="form-select">
                                            {/* <option value=""></option> */}
                                            <option value="en">English</option>
                                            <option value="fr">Français</option>
                                            {/* Add more languages as needed */}
                                        </select>

                                    </div>
                                    <div className="mobile-nav-toggler">
                                        <a className="navbar-toggle" onClick={toggleMenu}>
                                            <img src="assets/images/icons/icon-bar-2.png" alt="Mobile Toggle" />
                                        </a>
                                    </div>
                                    <nav className="main-menu navbar-expand-md navbar-light">
                                        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarSupportedContent">
                                            <ul className="navigation">
                                                <li><NavLink style={{ fontSize: '15px' }} to="/">{t('WELCOME')}</NavLink></li>
                                                <li className="dropdown">
                                                    <NavLink style={{ fontSize: '15px' }} to="/">{t('RENOVATION')}</NavLink>
                                                    <ul className="dropdown-menu">
                                                        <li><NavLink style={{ fontSize: '15px' }} to="/renovationAppartement">{t('APPARTEMENTS')}</NavLink></li>
                                                        <li><NavLink style={{ fontSize: '15px' }} to="/renovationHome">{t('HOME')}</NavLink></li>
                                                        <li><NavLink style={{ fontSize: '15px' }} to="/renovationBathroom">{t('OFFICES')}</NavLink></li>
                                                    </ul>
                                                </li>
                                                <li><NavLink style={{ fontSize: '15px' }} to="/achievements">{t('WORK PORTFOLIO')}</NavLink></li>
                                                <li><NavLink style={{ fontSize: '15px' }} to="/professional">{t('PROFESSIONAL')}</NavLink></li>
                                                <li><NavLink style={{ fontSize: '15px' }} to="/interview">{t('CUSTOMER REVIEW')}</NavLink></li>
                                                <li><NavLink style={{ fontSize: '15px' }} to="/contact">{t('CONTACT US')}</NavLink></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="right-column d-flex align-items-center">

                                <div className="contact-info-1 d-flex align-items-center">
                                    <div>
                                        <p className="contact-info-1-phone-tel fs_15 ff_oswald mb_0">CALL US NOW!</p>
                                        <h6 className="contact-info-1-phone-number fs_20 fw_medium">
                                            <a href="tel:+33786050975">+(33) 7 86 05 09 75</a>
                                        </h6>
                                    </div>
                                </div>
                                {/* Language Selector Dropdown */}
                                <div className="language-selector">
                                    <select onChange={handleLanguageChange} className="form-select">
                                        {options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                {/* <div className="header-link-btn">
                                    <a href="javascript:void(0);" className="btn-1">Request A Quote <span /></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header">
                    <div className="auto-container">
                        <div className="inner-container d-flex align-items-center justify-content-between">
                            <div className="left-column d-flex align-items-center">
                                <div className="logo-box">
                                    <div className="logo"><NavLink to="/"><img src="assets/images/logo.png" alt="Logo" /></NavLink></div>
                                </div>
                                <div className="ml_60 mr_60 d-none d-xl-block"><img src="assets/images/shape/shape-1.png" alt="Shape" /></div>
                                <div className="nav-outer">
                                    <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                                        <img src="assets/images/icons/icon-bar-2.png" alt="Mobile Toggle" />
                                    </div>
                                    <nav className="main-menu navbar-expand-md navbar-light">
                                        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                            <ul className="navigation">
                                                <li><NavLink style={{ fontSize: '15px' }} to="/">{t('WELCOME')}</NavLink></li>
                                                <li className="dropdown">
                                                    <NavLink style={{ fontSize: '15px' }} to="/">{t('RENOVATION')}</NavLink>
                                                    <ul className="dropdown-menu">
                                                        <li><NavLink style={{ fontSize: '15px' }} to="/renovationAppartement">{t('APPARTEMENTS')}</NavLink></li>
                                                        <li><NavLink style={{ fontSize: '15px' }} to="/renovationHome">{t('HOME')}</NavLink></li>
                                                        <li><NavLink style={{ fontSize: '15px' }} to="/renovationBathroom">{t('OFFICES')}</NavLink></li>
                                                    </ul>
                                                </li>
                                                <li><NavLink style={{ fontSize: '15px' }} to="/achievements">{t('WORK PORTFOLIO')}</NavLink></li>
                                                <li><NavLink style={{ fontSize: '15px' }} to="/professional">{t('PROFESSIONAL')}</NavLink></li>
                                                <li><NavLink style={{ fontSize: '15px' }} to="/interview">{t('CUSTOMER REVIEW')}</NavLink></li>
                                                <li><NavLink style={{ fontSize: '15px' }} to="/contact">{t('CONTACT US')}</NavLink></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="right-column d-flex align-items-center">

                                <div className="contact-info-1 d-flex align-items-center">
                                    <div>
                                        <p className="contact-info-1-phone-tel fs_15 ff_oswald mb_0">CALL US NOW!</p>
                                        <h6 className="contact-info-1-phone-number fs_20 fw_medium">
                                            <a href="tel:+33786050975">+(33) 7 86 05 09 75</a>

                                        </h6>
                                    </div>

                                </div>
                                {/* Language Selector Dropdown */}
                                <div className="language-selector">
                                    <select onChange={handleLanguageChange} className="form-select">
                                        <option value="">Select Language</option>
                                        <option value="en">English</option>
                                        <option value="fr">Français</option>
                                        {/* Add more languages as needed */}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`navbar-menu ${isOpen ? 'open' : ''}`}>
                    <ul className="navigation mb-4">
                        <li>
                            <NavLink
                                className="nav-link"
                                style={{ fontSize: 12, color: '#00496B', fontFamily: 'Poppins', fontWeight: 600 }}
                                to="/"
                            >
                                {t('welcome')}
                            </NavLink>
                        </li>
                        <li className="dropdown">
                            <NavLink
                                className="nav-link"
                                style={{ fontSize: 12, color: '#00496B', fontFamily: 'Poppins', fontWeight: 600 }}
                                to="/"
                            >
                                {t('renovation')}
                            </NavLink>
                            <ul className="dropdown-menu">
                                <li>
                                    <NavLink
                                        className="nav-link"
                                        to="/renovationAppartement"
                                        style={{ color: 'white', fontSize: 12, fontFamily: 'Poppins', fontWeight: 600 }}
                                    >
                                        {t('appartements')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="nav-link"
                                        to="/renovationHome"
                                        style={{ color: 'white', fontSize: 12, fontFamily: 'Poppins', fontWeight: 600 }}
                                    >
                                        {t('home')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="nav-link"
                                        to="/renovationBathroom"
                                        style={{ color: 'white', fontSize: 12, fontFamily: 'Poppins', fontWeight: 600 }}
                                    >
                                        {t('offices')}
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink
                                className="nav-link"
                                style={{ fontSize: 12, color: '#00496B', fontFamily: 'Poppins', fontWeight: 600 }}
                                to="/achievements"
                            >
                                {t('work_portfolio')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="nav-link"
                                style={{ fontSize: 12, color: '#00496B', fontFamily: 'Poppins', fontWeight: 600 }}
                                to="/professional"
                            >
                                {t('professional')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="nav-link"
                                style={{ fontSize: 12, color: '#00496B', fontFamily: 'Poppins', fontWeight: 600 }}
                                to="/interview"
                            >
                                {t('customer_review')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="nav-link"
                                style={{ fontSize: 12, color: '#00496B', fontFamily: 'Poppins', fontWeight: 600 }}
                                to="/contact"
                            >
                                {t('contact_us')}
                            </NavLink>
                        </li>
                        <div className="full-page-container">
                            <div className="logo-container">
                                <NavLink to="/">
                                    <img src="assets/images/logo.png" alt="Logo" />
                                </NavLink>
                            </div>
                        </div>
                    </ul>
                </div>
                <div className="nav-overlay">
                    <div className="cursor" />
                    <div className="cursor-follower" />
                </div>
            </header>
            <style>{`
                .navbar {
                    background-color: #333;
                    color: #fff;
                }

                .navbar-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                }

                .navbar-toggle {
                    background: none;
                    border: none;
                    font-size: 24px;
                    color: #fff;
                    cursor: pointer;
                }

                .navbar-menu {
                    display: none;
                    flex-direction: column;
                    width: 100%;
                }

                .navbar-menu.open {
                    display: flex;
                }

                .navbar-item {
                    text-decoration: none;
                    color: #fff;
                    padding: 10px;
                    text-align: center;
                }

                .navbar-item:hover {
                    background-color: #444;
                }

                /* Make the navbar visible only on mobile screens */
                @media (min-width: 768px) {
                    .navbar-menu {
                        display: none;
                    }
                        .language-selector-mob{
                        display : none;
                        }
                }

                /* Ensure the parent container is a flex container */
                @media (max-width: 1029px) {
                    .left-column {
                        display: flex;
                        align-items: center;
                        justify-content: space-between; /* Aligns items to both ends */
                        width: 100%; /* Ensure it takes full width */
                    }
                        .

                    .logo-box {
                        flex: 1; /* Take up remaining space */
                    }

                    .ml_60 {
                        margin-left: 60px;
                    }

                    .mr_60 {
                        margin-right: 60px;
                    }

                    .nav-outer {
                        display: flex;
                        align-items: center;
                    }

                    .mobile-nav-toggler {
                        margin-left: auto; /* Pushes the button to the end */
                    }

                    .navbar-toggle {
                        background: none;
                        border: none;
                        cursor: pointer;
                    }

                    .main-menu {
                        flex-grow: 1; /* Takes up remaining space */
                    }

                    .navigation li {
                        position: relative;
                    }

                    .dropdown-menu {
                        border-top: 4px solid #F75E06;
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        background-color: #2C2A49;
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                    }

                    .dropdown:hover .dropdown-menu {
                        display: block;
                    }

                    /* Ensure the parent container takes full height of the viewport */
                    .full-page-container {
                        display: flex;
                        justify-content: center; /* Center horizontally */
                        align-items: center; /* Center vertically */
                        width: 100vw; /* Full viewport width */
                        margin: 0;
                    }

                    .logo-container {
                        text-align: center; /* Optional: Center text if you have additional text */
                    }

                    .logo-container img {
                        width: 20%; /* Adjust the size of the image */
                               }
                        @media (max-width: 768px) {
                        .language-selector{
                        display : none;
                        }
            }
                         .form-select {
                    background-position: right center;
                    background-repeat: no-repeat;
                    background-size: 20px 15px; /* Adjust size as needed */
                    padding-right: 30px; /* Adjust padding to accommodate flag size */
                }

                .form-select option{
                    background-image: url('assets/images/flag-usa.png'); /* Replace with the path to your flag image */
                    color: red;
                }

                .form-select option {
                    background-image: url('assets/images/flag-fr.png'); /* Replace with the path to your flag image */
                }
            `}</style>
        </div>
    );
}
