import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function FeatureServices() {
    const { t } = useTranslation();

    return (
        <div>

            <section className="section-padding pt_130">
                <div className="gray-bg p_absolute l_0 t_0 r_0 h_548" />
                <div className="auto-container">
                    <div className="section_heading text-center mb_60">
                        <span className="section_heading_title_small">{t('OUR EXPERTISE')}</span>
                        <h2 className="section_heading_title_big">{t('DISCOVER OUR KNOW-HOW IN RENOVATION')}
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="feature-1-block text-center wow fadeInLeft" data-wow-delay=".2s" data-wow-duration=".8s">
                                <div className="feature-1-image hvr-img-zoom-1"><img src="assets/images/resource/feature-image-1.jpg" alt /></div>
                                <h4 className="feature-1-title">{t('Home Renovation')}</h4>
                                <p className="feature-1-text">{t('Home renovation includes both interior and exterior renovation work, such as kitchen renovation, bathroom renovation, facade renovation, insulation, etc')}</p>
                                <div className="feature-1-link"><NavLink className="feature-1-link-btn hvr-zoom-1 hvr-zoom-1-primary" to="/renovationHome">
                                    <i className="icon-5" />
                                </NavLink></div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="feature-1-block text-center wow fadeInLeft" data-wow-delay=".2s" data-wow-duration=".8s">
                                <div className="feature-1-image hvr-img-zoom-1"><img src="assets/images/resource/feature-image-2.jpg" alt /></div>
                                <h4 className="feature-1-title">{t('Apartment Renovation')}</h4>
                                <p className="feature-1-text">{t('Amet minim mollit no deserunt ulamco sit enim aliqua dolor sint velit officia  praesent maecenas senectus.')}</p>
                                <div className="feature-1-link"><NavLink className="feature-1-link-btn hvr-zoom-1 hvr-zoom-1-primary" to="/renovationAppartement"><i className="icon-6" /></NavLink></div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="feature-1-block text-center wow fadeInLeft" data-wow-delay=".2s" data-wow-duration=".8s">
                                <div className="feature-1-image hvr-img-zoom-1"><img src="assets/images/resource/feature-image-3.jpg" alt /></div>
                                <h4 className="feature-1-title">{t('Renovation of Professional Offices')}</h4>
                                <p className="feature-1-text">{t('Amet minim mollit no deserunt ulamco sit enim aliqua dolor sint velit officia  praesent  maecenas senectus.')}</p>
                                <div className="feature-1-link"><NavLink className="feature-1-link-btn hvr-zoom-1 hvr-zoom-1-primary" to="/renovationBathroom"><i className="icon-7" /></NavLink></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </div>
    )
}
