import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import RenovationHome from './Components/Renovation/Home';
import Appartement from './Components/Renovation/Appartement';
import Bathroom from './Components/Renovation/Bathroom';
import Contact from './Components/Contact/Contact';
import Achievements from './Components/Achievements/Achievements';
import Professional from './Components/Professional/Professional';
import Interview from './Components/Interview/Interview';
import './i18n'; // Import your i18n configuration


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/renovationAppartement" element={<Appartement />} />
          <Route path="/renovationHome" element={<RenovationHome />} />
          <Route path="/renovationBathroom" element={<Bathroom />} />
          <Route path="/professional" element={<Professional />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route path="/interview" element={<Interview />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
