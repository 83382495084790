import React, { useState } from 'react';
import Header from '../Home/Header';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';

export default function Contact() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);

        try {
            const response = await fetch('http://127.0.0.1:8000/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Handle success
                alert(t('email_sent_successfully'));
            } else {
                // Handle error
                alert(t('failed_to_send_message'));
            }
        } catch (error) {
            console.error('Error:', error);
            alert(t('error_sending_message'));
        }
    };

    return (
        <div>
            <Header />
            <section className="page-title text-center">
                <div className="bg-layer" style={{ backgroundImage: 'url(assets/images/background/page-title.jpg)' }} />
                <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-17.png)' }} />
                <div className="auto-container">
                    <div className="content-box">
                        <h1>{t('contact_us')}</h1>
                        <ul className="bread-crumb clearfix">
                            <li><a href="index.html">{t('home')}</a></li>
                            <li>{t('contact_us')}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="contact-form-section text-center">
                <div className="auto-container">
                    <div className="section_heading mb_50">
                        <span className="section_heading_title_small">{t('contact_info')}</span>
                        <h2 className="section_heading_title_big">{t('contact_information')}</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 info-column">
                            <div className="info-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><i className="icon-58" /></div>
                                    <h3>{t('phone_number')}</h3>
                                    <p>{t('emergency_cases')} <br /><a href="tel:208555-0112">+(33) 7 86 05 09 75</a> (24/7)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 info-column">
                            <div className="info-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><i className="icon-59" /></div>
                                    <h3>{t('email_address')}</h3>
                                    <p><a href="mailto:nasim@leprobat.com">nasim@leprobat.com</a><br /><a href="mailto:sohail@leprobat.com">sohail@leprobat.com</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 info-column">
                            <div className="info-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><i className="icon-60" /></div>
                                    <h3>{t('our_location')}</h3>
                                    <p>{t('farais_vallon_marseille')} <br />{t('france_13013')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-section">
                <div className="auto-container">
                    <div className="inner-container p_relative">
                        <div className="section_heading text-center mb_50">
                            <span className="section_heading_title_small">{t('get_in_touch')}</span>
                            <h2 className="section_heading_title_big">{t('get_in_touch_with_leprobat')}</h2>
                        </div>
                        <div className="form-inner">
                            <form onSubmit={handleSubmit} className="default-form">
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input
                                            type="text"
                                            name="username"
                                            placeholder={t('your_name')}
                                            value={formData.username}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={t('your_email')}
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <input
                                            type="text"
                                            name="phone"
                                            placeholder={t('phone')}
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <textarea
                                            name="message"
                                            placeholder={t('message')}
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn text-center">
                                        <button className="btn-1" type="submit" name="submit-form">{t('send_message')} <span /></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section className="google-map-section">
                <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55945.16225505631!2d-73.90847969206546!3d40.66490264739892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1601263396347!5m2!1sen!2sbd" width={600} height={535} frameBorder={0} style={{ border: 0, width: '100%' }} allowFullScreen aria-hidden="false" tabIndex={0} />
                </div>
            </section>

            <section className="newsletter-1">
                <div className="auto-container">
                    <div className="newsletter-1-bg">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <h4 className="newsletter-1-title">{t('subscribe_to_newsletter')}</h4>
                            </div>
                            <div className="col-lg-7">
                                <div className="newsletter-1-form">
                                    <form>
                                        <input type="email" placeholder={t('email_address')} />
                                        <button className="btn-1">{t('subscribe_now')} <span /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}
