import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function CIAHome() {
    const { t } = useTranslation();

    return (
        <div>

            <section className="cta-1-section">
                <div className="cta-1-bg" data-parallax="{&quot;y&quot;: 30}" style={{ backgroundImage: 'url(assets/images/background/bg-1.jpg)' }} />
                <div className="auto-container">
                    <div className="section_heading text-center mb_40">
                        <h2 className="section_heading_title_big c_light fs_70">{t('Need High Quality Cleaning ')}
                            &amp; <br />
                            {t('Maintenance Services?')}</h2>
                    </div>
                    <div className="text-center">
                        <div className="cta-1-link-bt"><a href="javascript:void(0);" className="btn-1">{t('Contact Us')}<span /></a></div>
                    </div>
                </div>
            </section>


        </div>
    )
}
