import React from 'react';
import Header from '../Home/Header';
import Footer from '../Footer';
import ProfessionalBanner from './ProfessionalBanner';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Professional() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <ProfessionalBanner />
      <section className="section-padding pt-0 mt-5">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-1-image-wrap text-center mb_30 p_relative">
                <div className="d_iblock">
                  <div className="about-1-image-1 hvr-img-zoom-1">
                    <img src="assets/images/resource/about12.jpg" style={{ width: 540 }} alt="About" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-5">
              <div className="section_heading mb_20">
                <span className="section_heading_title_small">{t('about')}</span>
                <h2 className="section_heading_title_big">
                  {t('about_specialist')}
                </h2>
              </div>
              <p className="aboout-1-desc mb_30">{t('company_specializing')}</p>
              <p className="aboout-1-desc mb_30">{t('support_in_creation')}</p>
              <p className="aboout-1-desc mb_30">{t('support_in_defining')}</p>
              <p className="aboout-1-desc mb_30">{t('neos_support')}</p>
              <p className="aboout-1-desc mb_30">{t('based_in')}</p>
              <div className="about-1-btn mb_30">
                <NavLink to="/contact" className="btn-1">{t('Contact Us')}<span /></NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding pt-0 mt-5">
        <div className="auto-container p-0">
          <div className="section_heading text-center mb_50">
            <span className="section_heading_title_small">{t('our_achievements')}</span>
            <h2 className="section_heading_title_big mb_20">{t('interior_renovation')}</h2>
          </div>
          <div className="row row-10">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="gallery-block-1">
                <div className="gallery-block-1-inner-box">
                  <div className="gallery-block-10-image">
                    <img src="assets/images/resource/image5.jpg" alt="Gallery Image 5" />
                  </div>
                  <div className="gallery-block-1-overlay">
                    <div className="gallery-block-1-lower-content">
                      <div className="gallery-block-1-link-btn">
                        <a href="assets/images/resource/image5.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="gallery-block-1">
                <div className="gallery-block-1-inner-box">
                  <div className="gallery-block-10-image">
                    <img src="assets/images/resource/image6.jpg" alt="Gallery Image 6" />
                  </div>
                  <div className="gallery-block-1-overlay">
                    <div className="gallery-block-1-lower-content">
                      <div className="gallery-block-1-link-btn">
                        <a href="assets/images/resource/image6.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="gallery-block-1">
                <div className="gallery-block-1-inner-box">
                  <div className="gallery-block-10-image">
                    <img src="assets/images/resource/image7.jpg" alt="Gallery Image 7" />
                  </div>
                  <div className="gallery-block-1-overlay">
                    <div className="gallery-block-1-lower-content">
                      <div className="gallery-block-1-link-btn">
                        <a href="assets/images/resource/image7.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="gallery-block-1">
                <div className="gallery-block-1-inner-box">
                  <div className="gallery-block-10-image">
                    <img src="assets/images/resource/image8.jpg" alt="Gallery Image 8" />
                  </div>
                  <div className="gallery-block-1-overlay">
                    <div className="gallery-block-1-lower-content">
                      <div className="gallery-block-1-link-btn">
                        <a href="assets/images/resource/image8.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6 ps-lg-5">
            <div className="section_heading mb_20">
              <h3 className="section_heading_title_big">{t('skills_in_planning')}</h3>
            </div>
            <ul>
              <li>{t('demolition_cleaning')}</li>
              <li>{t('partitioning')}</li>
              <li>{t('electricity')}</li>
              <li>{t('plumbing')}</li>
              <li>{t('false_ceilings')}</li>
              <li>{t('paint')}</li>
              <li>{t('flooring')}</li>
            </ul>
            <div className="section_heading mb_20 mt-5">
              <h3 className="section_heading_title_big">{t('locations_of_intervention')}</h3>
            </div>
            <ul>
              <li>{t('desk')}</li>
              <li>{t('open_space')}</li>
              <li>{t('store')}</li>
              <li>{t('shop')}</li>
              <li>{t('meeting_room')}</li>
              <li>{t('doctors_office')}</li>
              <li>{t('hotel')}</li>
              <li>{t('co_working_space')}</li>
              <li>{t('gym')}</li>
              <li>{t('wine_cellar')}</li>
              <li>{t('trade')}</li>
              <li>{t('training_center')}</li>
            </ul>
          </div>
          <div className="col-lg-6">
            <div className="about-1-image-wrap text-center mb_30 p_relative">
              <div className="d_iblock">
                <div className="about-1-image-1 hvr-img-zoom-1">
                  <img src="assets/images/resource/about13.jpg" style={{ width: 540 }} alt="About" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <section className="cta-1-section">
        <div className="cta-1-bg" data-parallax="{&quot;y&quot;: 30}" style={{ backgroundImage: 'url(assets/images/background/bg-1.jpg)' }} />
        <div className="auto-container">
          <div className="section_heading text-center mb_40">
            <h2 className="section_heading_title_big c_light fs_60">{t('cta_title')}</h2>
            <p style={{ color: 'white' }}>{t('cta_description')}</p>
          </div>
          <div className="text-center">
            <div className="cta-1-link-bt">
              <NavLink to="/contact" className="btn-1">{t('Contact Us')}<span /></NavLink>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

