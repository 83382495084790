import React from 'react'
import Header from '../Home/Header'
import Footer from '../Footer'
import RenovationBanner03 from './RenovationBanner03'
import { NavLink } from 'react-router-dom'

export default function Bathroom() {
  return (
    <div>

      <Header />
      <RenovationBanner03 />

      <section className="service-details pt_150 pb_120">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 content-side">
              <div className="service-details-content">
                <div className="content-one">
                  {/* <figure className="image-box"><img src="assets/images/resource/service-16.jpg" alt /></figure> */}
                  <div className="text-box">
                    <h2>WE CARRY OUT YOUR BATHROOM WORK IN AIX-EN-PROVENCE</h2>
                    <p>Whether you want to modernize your old bathroom or create a new relaxation space, our team of bathroom renovation professionals in Aix-en-Provence are here to support you every step of the way. With 20 years of experience and proven know-how, we understand the importance of combining style and functionality to create spaces that meet your specific needs.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <section className="section-padding pt-0">
                <div className="auto-container p-0">
                    {/* <div className="section_heading text-center mb_50">
                        <span className="section_heading_title_small">Portfolio</span>
                        <h2 className="section_heading_title_big mb_20">Some of Our Recent <br /> Memories</h2>
                    </div> */}
                    <div className="row row-10">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="gallery-block-1">
                                <div className="gallery-block-1-inner-box">
                                    <div className="gallery-block-10-image">
                                        <img src="assets/images/gallery/gallery-1.jpg" alt />
                                    </div>
                                    <div className="gallery-block-1-overlay">
                                        <div className="gallery-block-1-lower-content">
                                            <div className="gallery-block-1-link-btn">
                                                <a href="assets/images/gallery/gallery-1.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="gallery-block-1">
                                <div className="gallery-block-1-inner-box">
                                    <div className="gallery-block-10-image">
                                        <img src="assets/images/gallery/gallery-2.jpg" alt />
                                    </div>
                                    <div className="gallery-block-1-overlay">
                                        <div className="gallery-block-1-lower-content">
                                            <div className="gallery-block-1-link-btn">
                                                <a href="assets/images/gallery/gallery-2.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="gallery-block-1">
                                <div className="gallery-block-1-inner-box">
                                    <div className="gallery-block-10-image">
                                        <img src="assets/images/gallery/gallery-3.jpg" alt />
                                    </div>
                                    <div className="gallery-block-1-overlay">
                                        <div className="gallery-block-1-lower-content">
                                            <div className="gallery-block-1-link-btn">
                                                <a href="assets/images/gallery/gallery-3.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="gallery-block-1">
                                <div className="gallery-block-1-inner-box">
                                    <div className="gallery-block-10-image">
                                        <img src="assets/images/gallery/gallery-4.jpg" alt />
                                    </div>
                                    <div className="gallery-block-1-overlay">
                                        <div className="gallery-block-1-lower-content">
                                            <div className="gallery-block-1-link-btn">
                                                <a href="assets/images/gallery/gallery-4.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 content-side">
              <div className="service-details-content">
                <div className="content-one">
                  {/* <figure className="image-box"><img src="assets/images/resource/service-16.jpg" alt /></figure> */}
                  <div className="text-box">
                    <h2>CUSTOM BATHROOM RENOVATION IN AIX-EN-PROVENCE
                    </h2>
                    <p>Don't underestimate the importance of functionality when renovating a bathroom in Aix-en-Provence! Redesigning this space requires careful consideration of the needs of the occupants, whether it's a studio bathroom or a spacious family apartment.</p>
                    <p>With only 3 or 4 square meters, a small bathroom can be made functional, clean and equipped with storage, perfectly suited to a studio or a master suite. Play with colors, textures and lighting to create a most pleasant impression of volume!</p>
                    <p>When the family grows or needs change, a large family bathroom becomes essential: double sink, suitable towel dryer, and generous storage.</p>
                    <p>At Neos Batiment, our goal is to offer you a custom bathroom renovation, combining functionality and design. Whether you opt for a contemporary bathroom, open to the bedroom or closed, with separate toilets, tiles or vinyl, a bathtub or a walk-in shower, or even a finish with cement tiles, the choice is yours.</p>
                    <p>Get your quote now for a bathroom renovation in Aix-en-Provence!</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <section className="cta-1-section">
                <div className="cta-1-bg" data-parallax="{&quot;y&quot;: 30}" style={{ backgroundImage: 'url(assets/images/background/bg-1.jpg)' }} />
                <div className="auto-container">
                    <div className="section_heading text-center mb_40">
                        <h2 className="section_heading_title_small c_light">DO YOU HAVE A BATHROOM RENOVATION PROJECT 
                            &amp; <br />
                            IN AIX-EN-PROVENCE?</h2>
                            <p style={{color: 'white'}}>Our team will be happy to come and meet you to discuss your bathroom project and provide a budget estimate for your work.</p>
                    </div>
                    <div className="text-center">
                        <div className="cta-1-link-bt"><NavLink to="/contact" className="btn-1">CONTACT US<span /></NavLink></div>
                    </div>
                </div>
            </section>
      </section>
      <section className="section-padding pt-0">
                {/* <div className="about-1-shape-2" data-parallax="{&quot;y&quot;: -50}"><img src="assets/images/shape/shape-3.png" alt /></div> */}
                <div className="auto-container mb-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                {/* <div className="about-1-shape-1" data-parallax="{&quot;y&quot;: -50}"><img src="assets/images/shape/shape-2.png" alt /></div> */}
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1"><img src="assets/images/resource/about11.jpg" style={{width: 540}} alt /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                {/* <span className="section_heading_title_small">About Poolix</span> */}
                                <h3 className="section_heading_title_big">WHY ENTRUST US WITH THE RENOVATION OF YOUR BATHROOM?</h3>
                            </div>
                            <p className="aboout-1-desc mb_30">Our personalized approach to bathroom renovations begins with an in-depth consultation to understand your expectations, lifestyle, and vision. Whether you want a modern, sleek bathroom, a traditional space with a Provençal touch, or a unique combination of different design elements, our team is happy to help you realize your vision.</p>
                            <p className="aboout-1-desc mb_30">We work closely with you to select the materials, colours, accessories and fixtures that perfectly match your style and budget. Whether you are looking to optimise available space, add specific features or improve the energy efficiency of your bathroom, our team of experts will advise you on the best options available.</p>
                            <p className="aboout-1-desc mb_30">In addition to our expertise in design and material selection, we are committed to providing quality workmanship and meeting agreed deadlines. Our team of qualified professionals will ensure that your bathroom renovation project in Aix-en-Provence is carried out with precision and attention to detail, in order to create a space that meets your highest expectations.</p>
                            <p className="aboout-1-desc mb_30">Whether you own a house, apartment or vacation home in Aix-en-Provence, we are here to make your bathroom dreams a reality. Contact us today to schedule a consultation and get started on transforming your bathroom into a functional, stylish and enjoyable space.</p>
  
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className="auto-container mt-5">
                    <div className="row">
                        
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                {/* <span className="section_heading_title_small">About Poolix</span> */}
                                <h3 className="section_heading_title_big">WHY RENOVATE YOUR BATHROOM IN AIX EN PROVENCE?</h3>
                            </div>
                            <p className="aboout-1-desc mb_30">Modernization: Dated bathrooms can appear outdated in terms of style and design. Modernizing the bathroom is a common reason for renovation, allowing you to adopt current trends, incorporate innovative technologies, and improve the overall aesthetics of the space.</p>
                            <p className="aboout-1-desc mb_30">Improved functionality: A poorly designed or poorly functional bathroom can be frustrating on a daily basis. Renovation allows you to rethink the layout of the bathroom, optimize the available space and add practical features such as additional storage, a walk-in shower, a spa bath, etc.</p>
                            <p className="aboout-1-desc mb_30">Repair and maintenance: Over time, bathrooms can suffer damage due to wear and tear, moisture or leaks. Renovation helps to solve these problems by making necessary repairs and replacing damaged elements, such as tiles, sanitary ware, taps, etc.</p>
                            <p className="aboout-1-desc mb_30">Energy Saving: Bathroom renovations can also aim to improve energy efficiency by installing more energy-efficient fixtures and systems, such as low-flow faucets, water-saving showers, more efficient water heaters, LED lighting, etc. This helps reduce energy consumption and associated costs.</p>
                            <p className="aboout-1-desc mb_30">Property Valuation: Bathroom renovations can increase the overall value of the property. A modern, functional and attractive bathroom is a key element in attracting potential buyers and increasing the resale potential of the home.</p>
                            <p className="aboout-1-desc mb_30">Improved comfort and well-being: The bathroom is an intimate and personal space where we relax and recharge. A renovation can improve comfort and well-being by adding relaxation elements such as rain showers, spa baths, mood lighting, etc.</p>
  
                        </div>
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                {/* <div className="about-1-shape-1" data-parallax="{&quot;y&quot;: -50}"><img src="assets/images/shape/shape-2.png" alt /></div> */}
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1"><img src="assets/images/resource/about10.jpg"  style={{width: 520}}alt /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


      <Footer />

    </div>
  )
}
