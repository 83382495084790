import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function InterviewBanner() {
  const { t } = useTranslation();

  return (
    <div>
      {/* Page Title */}
      <section className="page-title text-center">
        <div className="bg-layer" style={{ backgroundImage: 'url(assets/images/background/page-title.jpg)' }} />
        <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-17.png)' }} />
        <div className="auto-container">
          <div className="content-box">
            <h2 style={{ color: "white" }}>
              {t('banner_title')}
            </h2>
            <p></p>
            <ul className="bread-crumb clearfix">
              <li><NavLink to="/">{t('welcome')}</NavLink></li>
              <li>{t('interview')}</li>
            </ul>
          </div>
        </div>
      </section>
      {/* End Page Title */}
    </div>
  );
}
