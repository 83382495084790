import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Header from '../Home/Header';
import Footer from '../Footer';
import AchievementsBanner from './AchievementsBanner';

export default function Achievements() {
    const { t } = useTranslation();
    const [images, setContent] = useState([]);

    useEffect(() => {
        axios.get('https://login.leprobat.com/api/image/read')
        .then(response => {
          setContent(response.data); // Assuming response.data is an array of image objects
          console.log(response.data);
        })
        .catch(error => console.error('Error fetching images:', error));
    }, []);

    return (
        <div>
            <Header />
            <AchievementsBanner />

            <section className="section-padding pt-0 mt-5">
                <div className="auto-container p-0">
                    <div className="section_heading text-center mb_50">
                        <h2 className="section_heading_title_big mb_20">{t('discover_achievements')}</h2>
                    </div>
                    <div className="row row-10">
                        {Array.isArray(images) && images.map(img => (
                            <div key={img.id} className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src={`https://login.leprobat.com/images/${img.name}`} alt={img.title} />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href={`https://login.leprobat.com/images/${img.name}`} className="lightbox-image" data-fancybox="gallery">
                                                        <span className="icon-24" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12 content-side">
                        <div className="service-details-content">
                            <div className="content-one">
                                <div className="text-box">
                                    <p>{t('discover_text1')}</p>
                                    <p>{t('discover_text2')}</p>
                                    <p>{t('discover_text3')}</p>
                                    <p>{t('discover_text4')}</p>
                                    <p>{t('discover_text5')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
