import React from 'react'
import Header from '../Home/Header'
import Footer from '../Footer'
import RenovationBanner01 from './RenovationBanner01'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function Appartement() {
    const { t } = useTranslation();
    return (
        <div>

            <Header />
            <RenovationBanner01 />
            <section className="service-details pt_150 pb_120">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 content-side">
                            <div className="service-details-content">
                                <div className="content-one">
                                    {/* <figure className="image-box"><img src="assets/images/resource/service-16.jpg" alt /></figure> */}
                                    <div className="text-box">
                                        <h2>{t('WE_RENOVATE_APARTMENTS_IN_MARSEILLE')}</h2>
                                        <p>{t('NEOS_SPECIALIZES')}</p>
                                        <p>{t('PURCHASED_APARTMENT')}</p>
                                        <p>{t('ALL_TYPES_OF_WORK')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-padding pt-0">
                    <div className="auto-container p-0">
                        {/* <div className="section_heading text-center mb_50">
            <span className="section_heading_title_small">Portfolio</span>
            <h2 className="section_heading_title_big mb_20">Some of Our Recent <br /> Memories</h2>
          </div> */}
                        <div className="row row-10">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src="assets/images/gallery/gallery-1.jpg" alt />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href="assets/images/gallery/gallery-1.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src="assets/images/gallery/gallery-2.jpg" alt />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href="assets/images/gallery/gallery-2.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src="assets/images/gallery/gallery-3.jpg" alt />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href="assets/images/gallery/gallery-3.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src="assets/images/gallery/gallery-4.jpg" alt />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href="assets/images/gallery/gallery-4.jpg" className="lightbox-image" data-fancybox="gallery"><span className="icon-24" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cta-1-section">
                    <div className="cta-1-bg" data-parallax="{&quot;y&quot;: 30}" style={{ backgroundImage: 'url(assets/images/background/bg-1.jpg)' }} />
                    <div className="auto-container">
                        <div className="section_heading text-center mb_40">
                            <h2 className="section_heading_title_small c_light">
                                {t('DO_YOU_HAVE_AN_APARTMENT_RENOVATION_PROJECT_IN_MARSEILLE')}
                            </h2>
                            <p style={{ color: 'white' }}>{t('CONTACT_US')}</p>
                        </div>
                        <div className="text-center">
                            <div className="cta-1-link-bt">
                                <NavLink to="/contact" className="btn-1">CONTACT US<span /></NavLink>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <section className="section-padding pt-0">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img src="assets/images/resource/about2.jpg" alt={t('WHY_ENTRUST_US')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <h3 className="section_heading_title_big">{t('WHY_ENTRUST_US')}</h3>
                            </div>
                            <p className="aboout-1-desc mb_30">{t('REQUIREMENTS')}</p>
                            <p className="aboout-1-desc mb_30">{t('STANDARDS_AND_AIDS')}</p>
                            <p className="aboout-1-desc mb_30">{t('SAVE_TIME')}</p>
                            <p className="aboout-1-desc mb_30">{t('20_YEARS_EXPERIENCE')}</p>
                        </div>
                    </div>
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <h3 className="section_heading_title_big">{t('WHERE_TO_FIND')}</h3>
                            </div>
                            <p className="aboout-1-desc mb_30">{t('RELY_ON_US')}</p>
                            <p className="aboout-1-desc mb_30">{t('EXPERT_SOLUTIONS')}</p>
                            <p className="aboout-1-desc mb_30">{t('MULTIPLE_SKILLS')}</p>
                            <p className="aboout-1-desc mb_30">{t('CONTACT_US')}</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img src="assets/images/resource/about3.jpg" alt={t('WHERE_TO_FIND')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img src="assets/images/resource/about4.jpg" alt={t('WHAT_WORK_CONSIDER')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <h3 className="section_heading_title_big">{t('WHAT_WORK_CONSIDER')}</h3>
                            </div>
                            <p className="aboout-1-desc mb_30">{t('RENO_SPECIFICITIES')}</p>
                            <p className="aboout-1-desc mb_30">{t('MANAGE_CRAFTSMEN')}</p>
                            <p className="aboout-1-desc mb_30">{t('TURNKEY_SUPPORT')}</p>
                            <p className="aboout-1-desc mb_30">{t('TRANSPARENT_COLLABORATION')}</p>
                        </div>
                    </div>
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <h3 className="section_heading_title_big">{t('COST_TO_RENOVATE')}</h3>
                            </div>
                            <p className="aboout-1-desc mb_30">{t('FULL_RANGE')}</p>
                            <p className="aboout-1-desc mb_30">{t('VARIATION_IN_COST')}</p>
                            <p className="aboout-1-desc mb_30">{t('QUOTE_MEET_EXPECTATIONS')}</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img src="assets/images/resource/about5.jpg" alt={t('COST_TO_RENOVATE')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />

        </div>
    )
}
