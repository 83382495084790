import React from 'react';
import Header from '../Home/Header';
import Footer from '../Footer';
import RenovationBanner02 from './RenovationBanner02';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function RenovationHome() {
    const { t } = useTranslation();

    return (
        <div>
            <Header />
            <RenovationBanner02 />

            <section className="service-details pt_150 pb_120">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 content-side">
                            <div className="service-details-content">
                                <div className="content-one">
                                    {/* <figure className="image-box"><img src="assets/images/resource/service-16.jpg" alt /></figure> */}
                                    <div className="text-box">
                                        <h2>{t('WE_RENOVATE_YOUR_HOUSE_TITLE')}</h2>
                                        <p>{t('WE_RENOVATE_YOUR_HOUSE_DESC')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="section-padding pt-0">
                    <div className="auto-container p-0">
                        <div className="row row-10">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src="assets/images/gallery/gallery-1.jpg" alt />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href="assets/images/gallery/gallery-1.jpg" className="lightbox-image" data-fancybox="gallery">
                                                        <span className="icon-24" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src="assets/images/gallery/gallery-2.jpg" alt />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href="assets/images/gallery/gallery-2.jpg" className="lightbox-image" data-fancybox="gallery">
                                                        <span className="icon-24" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src="assets/images/gallery/gallery-3.jpg" alt />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href="assets/images/gallery/gallery-3.jpg" className="lightbox-image" data-fancybox="gallery">
                                                        <span className="icon-24" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="gallery-block-1">
                                    <div className="gallery-block-1-inner-box">
                                        <div className="gallery-block-10-image">
                                            <img src="assets/images/gallery/gallery-4.jpg" alt />
                                        </div>
                                        <div className="gallery-block-1-overlay">
                                            <div className="gallery-block-1-lower-content">
                                                <div className="gallery-block-1-link-btn">
                                                    <a href="assets/images/gallery/gallery-4.jpg" className="lightbox-image" data-fancybox="gallery">
                                                        <span className="icon-24" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cta-1-section">
                    <div className="cta-1-bg" data-parallax="{&quot;y&quot;: 30}" style={{ backgroundImage: 'url(assets/images/background/bg-1.jpg)' }} />
                    <div className="auto-container">
                        <div className="section_heading text-center mb_40">
                            <h2 className="section_heading_title_small c_light">
                                {t('DO_YOU_HAVE_A_RENOVATION_PROJECT_TITLE')}
                            </h2>
                            <p style={{ color: 'white' }}>
                                {t('DO_YOU_HAVE_A_RENOVATION_PROJECT_DESC')}
                            </p>
                        </div>
                        <div className="text-center">
                            <div className="cta-1-link-bt">
                                <NavLink to="/contact" className="btn-1">
                                    {t('CONTACT US')}<span />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section className="section-padding pt-0">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img src="assets/images/resource/about6.jpg" style={{ width: 520 }} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <h3 className="section_heading_title_big">
                                    {t('CHOOSE_EXPERTISE_TITLE')}
                                </h3>
                            </div>
                            <p className="aboout-1-desc mb_30">
                                {t('ABOUT_NEOS_DESC_1')}
                            </p>
                            <p className="aboout-1-desc mb_30">
                                {t('ABOUT_NEOS_DESC_2')}
                            </p>
                            <p className="aboout-1-desc mb_30">
                                {t('ABOUT_NEOS_DESC_3')}
                            </p>
                        </div>
                    </div>
                </div>

                <br />
                <br />

                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <h3 className="section_heading_title_big">
                                    {t('WHY_RENOVATE_TITLE')}
                                </h3>
                            </div>
                            <p className="aboout-1-desc mb_30">
                                {t('WHY_RENOVATE_DESC_1')}
                            </p>
                            <p className="aboout-1-desc mb_30">
                                {t('WHY_RENOVATE_DESC_2')}
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img src="assets/images/resource/about7.jpg" style={{ width: 520 }} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />

                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img src="assets/images/resource/about8.jpg" style={{ width: 520 }} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <h3 className="section_heading_title_big">
                                    {t('IDEAL_PARTNER_TITLE')}
                                </h3>
                            </div>
                            <p className="aboout-1-desc mb_30">
                                {t('IDEAL_PARTNER_DESC')}
                            </p>
                        </div>
                    </div>
                </div>

                <br />
                <br />

                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <h3 className="section_heading_title_big">
                                    {t('SINGLE_POINT_CONTACT_TITLE')}
                                </h3>
                            </div>
                            <p className="aboout-1-desc mb_30">
                                {t('SINGLE_POINT_CONTACT_DESC')}
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img src="assets/images/resource/about9.jpg" style={{ width: 520 }} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}
