import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function AchievementsBanner() {
    const { t } = useTranslation();

    return (
        <div>
            {/* Page Title */}
            <section className="page-title text-center">
                <div className="bg-layer" style={{ backgroundImage: 'url(assets/images/background/page-title.jpg)' }} />
                <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-17.png)' }} />
                <div className="auto-container">
                    <div className="content-box">
                        <h2 style={{color: "white"}}>{t('work_portfolio')}</h2>
                        <h5 style={{color: "white", fontWeight: 'lighter', lineHeight: "3.25em"}}>{t('renovation_title')}</h5>
                        <p></p>
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to="/">{t('WELCOME')}</NavLink></li>
                            <li>{t('work_portfolio')}</li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* End Page Title */}
        </div>
    );
}
