import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();

    return (
        <div>
            <br />
            <br />
            <footer className="main-footer">
                <div className="footer-1-shape"><img src="assets/images/shape/footer-shape.svg" alt="Footer Shape" /></div>
                <div className="footer-1-middle">
                    <div className="auto-container">
                        <div className="p_relative">
                            <div className="logo mb_70"><img src="assets/images/logo-light.png" alt="Logo" /></div>
                            <div className="footer-1-shape-1 d-none d-lg-block"><img src="assets/images/shape/shape-5.png" alt="Footer Shape 1" /></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="contact-widget-1 mb_30">
                                    <div className="mb_30"><h4 className="fw_sbold c_light">{t('about_us')}</h4></div>
                                    <p className="mb_30">{t('about_us_description')}</p>
                                    <ul className="footer-social-icon d-flex align-items-center">
                                        <li><a href="javascript:void(0);" className="hvr-zoom-1-primary hvr-zoom-1"><i className="fab fa-facebook-f" /></a></li>
                                        <li><a href="javascript:void(0);" className="hvr-zoom-1-primary hvr-zoom-1"><i className="fab fa-twitter" /></a></li>
                                        <li><a href="javascript:void(0);" className="hvr-zoom-1-primary hvr-zoom-1"><i className="fab fa-linkedin-in" /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-6">
                                <div className="link-widget-1 mb_30">
                                    <div className="mb_30"><h4 className="fw_sbold c_light">{t('links')}</h4></div>
                                    <nav className="main-menu navbar-expand-md navbar-light">
                                        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                            <ul className="navigation" style={{ listStyleType: 'none', padding: 0 }}>
                                                <li style={{ padding: 0, marginBottom: '10px' }}>
                                                    <NavLink style={{ fontSize: '12px', padding: 0, color: 'white' }} to="/">{t('welcome')}</NavLink>
                                                </li>
                                                <li style={{ padding: 0, marginBottom: '10px' }} className="dropdown">
                                                    <NavLink style={{ fontSize: '12px', padding: 0, color: 'white' }} to="/">{t('renovation')}</NavLink>
                                                    <ul style={{ listStyleType: 'none', paddingLeft: '10px' }}>
                                                        <li><NavLink style={{ fontSize: '12px', padding: '10px', color: 'white' }} to="/renovationAppartement">{t('apartment')}</NavLink></li>
                                                        <li><NavLink style={{ fontSize: '12px', padding: '10px', color: 'white' }} to="/renovationHome">{t('home')}</NavLink></li>
                                                        <li><NavLink style={{ fontSize: '12px', padding: '10px', color: 'white' }} to="/renovationBathroom">{t('bathroom')}</NavLink></li>
                                                    </ul>
                                                </li>
                                                <li style={{ padding: 0, marginBottom: '10px' }}>
                                                    <NavLink style={{ fontSize: '12px', padding: 0, color: 'white' }} to="/achievements">{t('achievements')}</NavLink>
                                                </li>
                                                <li style={{ padding: 0, marginBottom: '10px' }}>
                                                    <NavLink style={{ fontSize: '12px', padding: 0, color: 'white' }} to="/professional">{t('professional')}</NavLink>
                                                </li>
                                                <li style={{ padding: 0, marginBottom: '10px' }}>
                                                    <NavLink style={{ fontSize: '12px', padding: 0, color: 'white' }} to="/interview">{t('interview')}</NavLink>
                                                </li>
                                                <li style={{ padding: 0, marginBottom: '10px' }}>
                                                    <NavLink style={{ fontSize: '12px', padding: 0, color: 'white' }} to="/contact">{t('contact')}</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div className="col-lg-3 offset-lg-1 col-md-6">
                                <div className="link-widget-1 mb_30">
                                    <div className="mb_30"><h4 className="fw_sbold c_light">{t('working_hours')}</h4></div>
                                    <p className="mb_25">{t('working_hours_description')}</p>
                                    <p>{t('working_hours_schedule')}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="contact-widget-1 mb_30">
                                    <div className="mb_30"><h4 className="fw_sbold c_light">{t('get_in_touch')}</h4></div>
                                    <ul className="contact-widget-1-list">
                                        <li>{t('address')}</li>
                                        <li><NavLink to="mailto:nasim@leprobat.com">nasim@leprobat.com</NavLink></li>
                                        <li><NavLink href="mailto:sohail@leprobat.com">sohail@leprobat.com</NavLink></li>
                                        <li><a href="tel:+33786050975">+(33) 7 86 05 09 75</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="footer-bottom-row">
                            <div className="footer-bottom-text">{t('copyright')} <a href="javascript:void(0);">Poolix</a> {t('all_right_reserved')}</div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
