import React, { useState, useEffect } from 'react';
import Header from './Home/Header'
import HomeBanner from './Home/HomeBanner'
import FeatureServices from './Home/FeatureServices'
import AboutHome from './Home/AboutHome'
import ServicesHome from './Home/ServicesHome'
import WhyChooseUs from './Home/WhyChooseUs'
import TeamHome from './Home/TeamHome'
import PortfolioHome from './Home/PortfolioHome'
import TestimonialHome from './Home/TestimonialHome'
import CIAHome from './Home/CIAHome'
import Footer from './Footer'
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



export default function Home() {
  const { t } = useTranslation();
  // const toggleMenu = () => setIsOpen(!isOpen);
  const currentLocation = useLocation();
    // Scroll to top on location change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentLocation]);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  return (
    <>
      <div class="page-wrapper">
        <Header /> {/*Language changed*/}
        <HomeBanner />{/*Language changed*/}
        <AboutHome/>{/*Language changed*/}
        <FeatureServices />{/*Language changed*/}
        {/* <ServicesHome /> */}
        {/* <WhyChooseUs /> */}
        <TeamHome />
        <PortfolioHome />{/*Language changed*/}
        {/* <TestimonialHome /> */}
        <CIAHome />
        <Footer />
        {/*Scroll to top*/}
        <div className="scroll-to-top" onClick={scrollToTop}>
          <div>
            <div className="scroll-top-inner">
              <div className="scroll-bar">
                <div className="bar-inner" />
              </div>
              <div className="scroll-bar-text">Go To Top</div>
            </div>
          </div>
        </div>
        {/* Scroll to top end */}

      </div>
    </>
  )
}
