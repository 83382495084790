import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function RenovationBanner01() {
    const { t } = useTranslation();

    return (
        <div>
            {/* Page Title */}
            <section className="page-title text-center">
                <div className="bg-layer" style={{ backgroundImage: 'url(assets/images/background/page-title.jpg)' }} />
                <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-17.png)' }} />
                <div className="auto-container">
                    <div className="content-box">
                        <h2 style={{color: "white"}}>{t('APARTMENT_RENOVATION_TITLE')}</h2>
                        <h6 style={{color: "white", fontWeight: 'lighter', lineHeight: "3.25em"}}>
                            {t('APARTMENT_RENOVATION_SUBTITLE')}
                        </h6>
                        <p></p>
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to="/">{t('WELCOME')}</NavLink></li>
                            <li>{t('APARTMENT')}</li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* End Page Title */}
        </div>
    );
}
