import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function AboutHome() {
    const { t } = useTranslation();

    return (
        <div style={{ backgroundColor: "#F7F7F5" }}>
            <br />
            <br />
            <br />
            <br />
            <section className="section-padding pt-0" style={{ backgroundColor: "#F7F7F5" }}>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-1-image-wrap text-center mb_30 p_relative">
                                <div className="d_iblock">
                                    <div className="about-1-image-1 hvr-img-zoom-1">
                                        <img style={{ width: 550 }} src="assets/images/resource/about.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="section_heading mb_20">
                                <span className="section_heading_title_small">{t('ABOUT')}</span>
                                <h2 className="section_heading_title_big">{t('A FAMILY RENOVATION BUSINESS')}</h2>
                            </div>
                            <p className="aboout-1-desc mb_30">{t('OUR_COMPANY_DESC_1')}</p>
                            <p className="aboout-1-desc mb_30">{t('OUR_COMPANY_DESC_2')}</p>
                            <p className="aboout-1-desc mb_30">{t('OUR_COMPANY_DESC_3')}</p>
                            <p className="aboout-1-desc mb_30">{t('OUR_COMPANY_DESC_4')}</p>
                            <div className="about-1-btn mb_30">
                                <NavLink to="/contact" className="btn-1">{t('Contact Us')}<span /></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
